<template>
  <div id="app">
    <component :is="layout">
      <router-view></router-view>
    </component>
  </div>
</template>

<script>
const defaultLayout = "not-logged-in";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("auth", ["user"]),
    layout() {
      if (this.user && this.user.profile.role === "Administrator") {
        return "admin-layout";
      }

      if (this.user && this.user.profile.role === "ClaimsAdmin") {
        return "claimsAdmin-layout";
      }

      if (this.user && this.user.profile.role === "SuperAdmin") {
        return "superAdmin-layout";
      }

      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
  },
};
</script>
<style>
html {
  overflow-y: auto !important;
}
</style>
