import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import Default from "./layouts/Default.vue";
import Admin from "./layouts/Admin.vue";
import SuperAdmin from "./layouts/SuperAdmin.vue";
import ClaimsAdmin from "./layouts/ClaimsAdmin.vue";
import NoSidebar from "./layouts/NoSidebar.vue";
import NotLoggedIn from "./layouts/NotLoggedIn.vue";
import None from "./layouts/None.vue";
import NoSideBarNotLoggedIn from "./layouts/NoSidebarNotLoggedIn.vue";
import axios from "axios";
import VueHtmlToPaper from "vue-html-to-paper";

Vue.component("default-layout", Default);
Vue.component("admin-layout", Admin);
Vue.component("superAdmin-layout", SuperAdmin);
Vue.component("claimsAdmin-layout", ClaimsAdmin);
Vue.component("none-layout", None);
Vue.component("no-sidebar-layout", NoSidebar);
Vue.component("not-logged-in-layout", NotLoggedIn);
Vue.component("no-sidebar-not-logged-in-layout", NoSideBarNotLoggedIn);

Vue.config.productionTip = false;

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css",
    "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};

Vue.use(Vuelidate);
Vue.use(VueHtmlToPaper, options);
//AuthService.init();

new Vue({
  router,
  store,
  vuetify,
  async created() {
    this.$store.dispatch("auth/setUser");

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }
        return Promise.reject(error);
      },
    );
  },
  //methods: mapActions("provider", ["getProfile"]),
  render: (h) => h(App),
}).$mount("#app");
