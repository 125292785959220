<template>
  <v-container>
    <div v-show="provider.isSuspended">
      <h3 class="red--text">
        Your account has been suspended. You will not be able to submit any
        claims. Please contact administrator.
      </h3>
      <v-alert dense outlined type="error" v-show="provider.adminMessage">
        {{ provider.adminMessage }}
      </v-alert>
    </div>
    <h1 class="mb-3 text-h5">Provider Claim Submission</h1>
    <v-card flat class="mx-auto">
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="(e) => submitClaim(e)"
      >
        <v-expansion-panels focusable tile multiple :value="expanded">
          <v-expansion-panel>
            <v-expansion-panel-header>
              Search for a member to add a Claim
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <AddClaimPersonalInfo
                ref="addClaimPersonalInfo"
                @memberSelected="onMemberSelected"
                @addNewMember="addMember"
                @reset="resetAllFields"
                @officeSelected="setOfficeName"
                :member="member"
                :loading="loading"
                :isStatusNewProvider="isStatusNewProvider"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              Add Claims - Select member to start</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <div
                v-if="coverages.length === 0 && showExpenses"
                class="red--text ma-2"
              >
                Member has no coverages offered by provider or plan
              </div>
              <div v-if="planNotFound" class="red--text ma-2">
                Plan registered to member not found
              </div>
              <div v-if="showExpenses">
                <v-row>
                  <v-col class="mt-0 mt-md-3">
                    <v-text-field
                      v-model="member.email"
                      label="Enter Member's e-mail (optional)"
                      :rules="emailRules"
                      dense
                      type="e-mail"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="12" md="6" class="pt-0">
                    <v-checkbox
                      :label="`Was this claim result of an accident? : ${
                        isAccident ? 'Yes' : 'No'
                      }`"
                      v-model="isAccident"
                      color="primary"
                      hide-details
                      name="isAccident"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6" class="pt-0" v-if="isAccident">
                    <v-select
                      v-model="accidentType"
                      :items="accidentTypeOptions"
                      :rules="accidentTypeRules"
                      label="Accident Type"
                      name="accidentType"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
                <NewExpense
                  v-for="n in expenseIds"
                  :key="n"
                  :dependents="dependents"
                  :coverages="coverages"
                  :expenseId="n"
                  :planNumber="member.plan"
                >
                  <v-btn
                    tile
                    :disabled="expenseIds.length <= 1"
                    v-show="n !== 0"
                    color="secondary"
                    class="mr-4"
                    @click="removeClaim(n)"
                  >
                    REMOVE
                  </v-btn>
                </NewExpense>
                <v-btn tile color="secondary" class="mt-4" @click="addClaim">
                  ADD ANOTHER CLAIM</v-btn
                >
                <div class="mt-4">
                  <v-tooltip bottom max-width="800">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <a>
                          <v-icon left size="20" color="#007CB5" class="mr-1">
                            mdi-information
                          </v-icon>
                          <small class="black--text">
                            <i>
                              The PBAS Provider Portal allows online claim
                              submission within 30 days of the date of service.
                            </i>
                          </small>
                        </a>
                      </span>
                    </template>
                    <span>
                      <i>
                        The PBAS Provider Portal allows online claim submission
                        within 30 days of the date of service. If this period
                        has elapsed, you must submit the claim manually for
                        consideration. Claims can be submitted by email, mail,
                        or fax. The timeframe to submit claims varies by Plan.
                        For questions or concerns, please see the contact
                        information at the bottom of this page.
                      </i>
                    </span>
                  </v-tooltip>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card-actions>
          <v-btn
            tile
            color="secondary"
            class="mt-4 mb-6"
            :disabled="!valid || isStatusNewProvider || !showExpenses"
            type="submit"
            :loading="loading"
            >SUBMIT</v-btn
          >
          <small class="ml-4 red--text" v-if="isStatusNewProvider"
            >You will not be able to submit claim until your profile is
            approved</small
          >
        </v-card-actions>
      </v-form>
    </v-card>

    <v-row justify="center">
      <v-dialog v-model="successDialog" persistent max-width="600">
        <v-card>
          <v-card-title class="text-h5"> Claim Submission Sent </v-card-title>
          <v-card-text class="black--text"
            >Your Claim ID: <strong>{{ submissionId }}</strong> has been sent.
            You can find and view submission on the
            <router-link :to="'search-submissions'"
              >Search Submissions</router-link
            >
            page.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="print"> PRINT </v-btn>
            <v-btn text @click="close"> CLOSE </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Confirmation printout -->
    <div id="printSection" v-show="false">
      <v-container>
        <v-row align="center" justify="center">
          <v-card max-width="800" class="mx-auto">
            <v-container>
              <v-row align="center" justify="space-around">
                <v-col cols="5" class="text-h5 ml-2"> Providers Portal </v-col>
                <v-col cols="5">
                  <v-img
                    :src="require('@/assets/pbas_group_logo.png')"
                    contain
                    height="60"
                  />
                </v-col>
              </v-row>
              <v-card-title> Claim Submission Sent </v-card-title>
              <v-card-text class="black--text"
                >Your Claim ID: <strong>{{ submissionId }}</strong> has been
                sent. You can find and view submission on the<br />
                <router-link :to="'search-submissions'"
                  >Search Submissions</router-link
                >
                page.
              </v-card-text>
              <ClaimDetailPrintout :claim="printout" />
            </v-container>
          </v-card>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AddClaimPersonalInfo from "../components/AddClaimPersonalInfo.vue";
import ClaimDetailPrintout from "../components/ClaimDetailPrintout.vue";
import NewExpense from "../components/NewExpense.vue";
import ClaimService from "../services/ClaimService";
import CoveragesService from "../services/CoveragesService";

export default {
  components: { AddClaimPersonalInfo, NewExpense, ClaimDetailPrintout },
  data() {
    return {
      successDialog: false,
      officeName: "",
      claimOffice: {},
      referralDate: "",
      physician: "",
      physicianRegNumber: "",
      valid: false,
      expanded: [0, 1],
      tab: null,
      timeout: null,
      member: null,
      showExpenses: false,
      expenseIds: [0],
      expenseId: 0,
      loading: false,
      coverages: [],
      planNotFound: false,
      isAccident: false,
      accidentType: null,
      submissionId: "",
      accidentTypeRules: [
        (v) => !!v || "Please select type",
        (v) =>
          v !== "Workplace" ||
          "This is not covered on this benefit plan, please contact your administrator",
      ],
      emailRules: [(v) => !v || /.+@.+\..+/.test(v) || "E-mail must be valid"],
      accidentTypeOptions: ["Workplace", "Motor vehicle", "Other"],
      printout: {},
      emptyMember: {
        address: "",
        certificate: "",
        city: "",
        comments: "",
        dependents: [],
        dob: "",
        email: "",
        firstname: "",
        lastname: "",
        memberId: "",
        phoneNumber: "",
        plan: "",
        subpolicy: "",
        postalCode: "",
        province: "",
      },
    };
  },
  mounted() {
    this.member = this.emptyMember;
  },
  watch: {
    expenseIds: function () {
      this.validate();
    },
    provider: function () {
      this.officeName = this.provider.offices[0].officeName;
      this.claimOffice = this.provider.offices[0];
    },
  },
  computed: {
    ...mapState("provider", ["provider"]),
    isStatusNewProvider() {
      return !this.provider.providerNumber || this.provider.providerNumber === "";
    },
    dependents() {
      const dependents =
        this.member?.dependents?.map((d) => {
          const dob = d?.DateOfBirth ? d?.DateOfBirth.split("/") : "";
          const formattedDob = `${dob[2]}-${dob[0]}-${dob[1]}`;
          return (d?.FirstName?.toUpperCase() + (d?.DateOfBirth ? (' - ' + formattedDob) : "")) || [];
        });
      if (dependents) {
        dependents.unshift(this.member?.firstname?.toUpperCase());
      }

      return dependents;
    },
  },
  methods: {
    ...mapActions("notification", ["show"]),
    validate() {
      this.$refs.form.validate();
    },
    resetAllFields() {
      this.loading = false;
      this.member = this.emptyMember;
      this.expenseIds = [0];
      this.expenseId = 0;
      this.planNotFound = false;
      this.showExpenses = false;
    },
    close() {
      this.successDialog = false;
      this.submissionId = "";
    },

    print() {
      this.$htmlToPaper("printSection");
      this.successDialog = false;
    },
    setOfficeName(office) {
      this.officeName = office;
      this.claimOffice = this.provider.offices.filter(
        (office) => office.officeName === this.officeName
      )[0];
    },
    addMember(officeName) {
      // prepopulate new member info with provider and office data
      this.member = Object.assign({}, this.emptyMember);
      this.member.dependents = [];
      const currentOffice = this.provider.offices.filter(
        (office) => office.officeName === officeName
      )[0];
      this.member.clinicEmail = this.provider.email;
      this.member.officeId = currentOffice.officeId;
      this.member.providerId = this.provider.providerId;
      this.member.providerNumber = this.provider.providerNumber;
      this.showExpenses = false;
    },
    addClaim() {
      this.expenseId++;
      this.expenseIds.push(this.expenseId);
    },
    removeClaim(id) {
      const index = this.expenseIds.indexOf(id);
      this.expenseIds.splice(index, 1);
    },
    async submitClaim(e) {
      this.loading = true;
      const formData = new FormData(e.target);

      const payload = {
        Expense: [],
        IsProvincialMaxed: false,
        IsAccident: false,
      };
      let key = "";
      let value = "";
      let isExpense = false;
      let currentExpense = null;
      for (var pair of formData.entries()) {
        [key, value] = pair;
        //console.log("key: ", key, "value: ", value);
        if (key === "expenseId" && value !== currentExpense) {
          isExpense = true;
          currentExpense = value;
          payload.Expense.push({});
          continue;
        }
        // get expense data from form
        if (isExpense) {
          const lastIndex = payload.Expense.length - 1;
          payload.Expense[lastIndex]["Base64"] = "";
          if (key === "coverageType") {
            const coverage = this.coverages.find((c) => c.name == value);
            //payload.Expense[lastIndex]["coverageTypeID"] = coverage.coverageTypeID;
            payload.Expense[lastIndex]["CoverageTypeID"] = (
              coverage.customId || coverage.assignedId
            )
              .toString()
              .padStart(2, "0");
          }
          payload.Expense[lastIndex]["AdditionalInfoUploaded"] = "No";
          payload.Expense[lastIndex]["AdditionalInfoSubmitted"] = "No";
          payload.Expense[lastIndex]["AdditionalInfoFile"] = null;
          payload.Expense[lastIndex]["AdditionalInfoFileContent"] = "";
          payload.Expense[lastIndex]["Comments"] = "";
          if (key === "referralDate") {
            this.referralDate = value;
            payload.Expense[lastIndex]["ReferralDate"] = this.referralDate;
            continue;
          }
          if (key === "physician") {
            this.physician = value;
            continue;
          }
          if (key === "physicianRegNumber") {
            this.physicianRegNumber = value;
            payload.Expense[lastIndex][
              "Comments"
            ] = `PHY: ${this.physician} REG: ${this.physicianRegNumber} DATE: ${this.referralDate}`;
            continue;
          }
          if (key === "expenseDate") {
            payload.Expense[lastIndex]["ExpenseDate"] = formatDate(value);
            continue;
          }
          // get PatientId on Expense
          if (key === "PatientName") {
            const patientName = value?.split(" - ")[0];
            payload.Expense[lastIndex]["PatientName"] = patientName;
            payload.Expense[lastIndex]["DependantNo"] =
              this.member.dependents.find(
                (d) => d.FirstName.toUpperCase() === patientName?.toUpperCase()
              )?.PatientId || "00";
            continue;
          }
          payload.Expense[lastIndex]["ReceiptFile"] = "";
          payload.Expense[lastIndex]["COBFile"] = null;
          payload.Expense[lastIndex]["SendToProvider"] = null;
          payload.Expense[lastIndex]["SendToProviderConfirmation"] = "";
          payload.Expense[lastIndex]["FlexibleBenefitAccount"] = null;
          payload.Expense[lastIndex]["ProviderName"] = null;
          //   this.provider.providerName;
          payload.Expense[lastIndex][key] = value;
        } else {
          if (key === "isAccident") value = true;
          this.physician = "";
          this.physicianRegNumber = "";
          this.referralDate = "";
          payload[key] = value;
        }
      }

      let totalAmount = 0;
      payload.Expense.forEach((expense) => {
        totalAmount += parseFloat(expense.Amount);
      });
      if (totalAmount === 0) {
        this.loading = false;
        return;
      }
      payload["Submissionid"] = generateSubmissionId(this.member.plan);
      payload["StudentId"] = this.member.memberId;
      payload["FormType"] = "Provider Claims Submission";
      payload["ProviderNumber"] = this.provider.providerNumber;
      payload["ClaimAmountTotal"] = totalAmount.toFixed(2);
      payload["ProviderNumber"] = this.provider.providerNumber;
      payload["PayTo"] = "Provider";
      payload["BusinessID"] = this.claimOffice.businessID;
      payload["BusinessName"] = this.claimOffice.businessName;
      payload.HealthOffice = this.claimOffice.businessName;
      payload["BusinessEmail"] = this.claimOffice.directDepositEmail;
      payload["ProviderId"] = this.provider.providerId;
      payload["PlanNumber"] = this.member.plan;
      payload["IsDrawbridgePlan"] = this.member.isDrawbridgePlan;
      payload["PlanType"] = this.member.subpolicy || "";
      payload["Comments"] = "";
      // payload["Comments"] = "#A$D1T:100 - Provider Portal Test#"; // Only use for test submissions to be ignored
      payload["FirstName"] = this.member.firstname;
      payload["LastName"] = this.member.lastname;
      payload["DateOfBirth"] = formatMembersDob(this.member.dob);
      payload["Email"] = this.claimOffice.directDepositEmail;
      payload["StudentEmail"] = this.member.email || "";
      payload["PhoneNumber"] = this.member.phoneNumber || "";
      payload["Address"] = this.member.address || "";
      payload["City"] = this.member.city || "";
      payload["Province"] = this.member.province || "";
      payload["PostalCode"] = this.member.postalCode || "";
      payload["AddressOutsideCanada"] = "No";
      payload["ForeignAddress"] = "";
      payload["ForeignCity"] = "";
      payload["ForeignPostalCode"] = "";
      payload["ForeignCountry"] = "";
      payload["OccupationalInjury"] = this.isAccident ? "Yes" : "No";
      payload["AlternatePlan"] = "No";
      payload["AdditionalCoverage"] = "";
      payload["RelationshipToPolicyHolder"] = "";
      payload["NameOfInsuranceProvider"] = "";
      payload["PolicyHolderPolicyNumber"] = "";
      payload["SpouseDateOfBirth"] = "//";
      payload["DependantPostSecondary"] = "No";
      payload["StudentType"] = "Member";
      payload["DateEnrolled"] = "12/31/1969";
      payload["DateCompleted"] = "12/31/1969";
      payload["Dependent"] = this.member.dependents;
      payload["Proof"] = formProofObject(payload.Expense) || "";

      console.log("Submission payload sent: ", payload);
      ClaimService.submitClaim(payload)
        .then((res) => {
          console.log(res.data);
          this.loading = false;
          this.submissionId = payload.Submissionid;
          this.printout = payload;

          this.successDialog = true;
          this.resetAllFields();
          this.$refs.addClaimPersonalInfo.reset();
        })
        .catch((err) => {
          const notification = {
            type: "error",
            message: `Claim is not sent: ${err.response.data ?? err.message}`,
          };
          this.show(notification);
          this.loading = false;
        });

      // function generateSubmissionId(plan) {
      //   const d = new Date();
      //   const month = (d.getMonth() + 1).toString().padStart(2, "0");
      //   const day = d.getDate().toString().padStart(2, "0");
      //   const year = d.getFullYear();
      //   const hour = d.getHours().toString().padStart(2, "0");
      //   const mins = d.getMinutes().toString().padStart(2, "0");
      //   const sec = d.getSeconds().toString().padStart(2, "0");
      //   const millisec = d.getMilliseconds().toString().padStart(3, "0");
      //   return `CPX-${plan}-${month}${day}${year}${hour}${mins}${sec}${millisec}`;
      // }

      function generateSubmissionId(plan) {
        const d = new Date();
        const month = (d.getMonth() + 1).toString().padStart(2, "0");
        const day = d.getDate().toString().padStart(2, "0");
        const year = d.getFullYear().toString().slice(-2);
        const hour = d.getHours().toString().padStart(2, "0");
        const mins = d.getMinutes().toString().padStart(2, "0");
        const sec = d.getSeconds().toString().padStart(2, "0");
        const millisec = d.getMilliseconds().toString().padStart(3, "0").slice(0, 2);
        return `CPP-${plan}-${day}${month}${year}${hour}${mins}${sec}${millisec}`;
      }

      function formProofObject(expenses) {
        const proof = [];
        expenses.forEach((e) => {
          if (e.AdditionalInfoFile !== "")
            proof.push({
              PatientId: e.DependantNo,
              FileName: e.AdditionalInfoFile,
              FileNameContent: "",
            });
        });
        return proof;
      }

      function formatDate(value) {
        const dateArray = value.split("-");
        return dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0];
        //return value.split("-").reverse().join("/");
      }

      function formatMembersDob(date) {
        return `${date.substring(4, 6)}/${date.substring(6, 8)}/${date.substring(0, 4)}`;
      }

      // function toBase64(file) {
      //   return new Promise((resolve, reject) => {
      //     const reader = new FileReader();
      //     reader.readAsDataURL(file);
      //     reader.onloadend = () =>
      //       resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
      //     reader.onerror = (error) => reject(error);
      //   });
      // }
    },

    formatDob(date) {
      return `${date.substring(4, 6)}/${date.substring(6, 8)}/${date.substring(0, 4)}`;
    },

    async onMemberSelected(member) {
      const formatedDependents = [];
      member.dependents.forEach((dependent) => {
        const dependentFormated = {};
        const names = dependent.firstName?.split(" ");
        if (names.length > 1) {
          dependentFormated.FirstName = names[0].concat(" ").concat(names[1]);
          dependentFormated.LastName = dependent.lastName && dependent.lastName.length !== 0 ? dependent.lastName : member.lastname;
        } else {
          dependentFormated.FirstName = names[0];
          dependentFormated.LastName = dependent.lastName && dependent.lastName.length !== 0 ? dependent.lastName : member.lastname;
        }
        dependentFormated.MemberId = dependent.memberId;
        dependentFormated.PatientId = dependent.patientId;
        dependentFormated.DependantId = dependent.patientId;
        dependentFormated.Relationship = dependent.relationship;
        dependentFormated.Gender = dependent.gender;
        dependentFormated.DateOfBirth = this.formatDob(dependent.dateOfBirth);
        formatedDependents.push(dependentFormated);
      });
      this.member = member;
      this.member.dependents = formatedDependents;
      await this.filterAvaliableCoverages(member);
    },

    async filterAvaliableCoverages(member) {
      // Get member plans and check if member has coverages included in provider's coverages
      let notification = "";
      try {
        let planToGet = member.plan;

        // Special case for members of plan 777 which same as 247
        if (member.plan.trim() === "777") {
          planToGet = "247" + "-" + member.subpolicy;
        }

        let res = await CoveragesService.getPlanCoverages(planToGet);
        const plan = res.data;
        this.member.isDrawbridgePlan = plan.isDrawbridgePlan;
        const planEffectiveDate = new Date(plan.effectiveDate);

        if (planEffectiveDate > Date.now()) {
          notification = {
            type: "error",
            closeBtn: true,
            message: `Member's Plan is not effective until ${plan.effectiveDate.slice(
              0,
              10
            )}`,
          };
          this.show(notification);
          this.loading = false;
          this.showExpenses = false;
          return;
        }

        const planCoverages = res.data.coverages;
        this.planNotFound = false;
        if (planCoverages && planCoverages.length) {
          const providerCoverageNames = this.provider.coverages.map((c) => c.name);
          const filteredAvailableCoverages = planCoverages.filter((coverage) =>
            providerCoverageNames.includes(coverage.name)
          );
          this.coverages = filteredAvailableCoverages;
          this.loading = false;
          this.showExpenses = true;
          return;
        }
        notification = {
          type: "error",
          message: `Member Has no coverages!`,
        };
      } catch (e) {
        if (e.response.status === 404) {
          this.planNotFound = true;
        }
        notification = {
          type: "error",
          message: `Plan not found`,
        };
      }
      this.show(notification);
      this.loading = false;
      this.showExpenses = false;
      return;
    },
  },
};
</script>

<style scoped>
p {
  text-align: justify;
}
</style>
