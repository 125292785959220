<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-row>
        <v-col cols="auto">Provider Details</v-col>
        <v-col cols="auto" v-if="provider.isSuspended">
          <v-chip small dark color="red">
            <v-icon small class="mr-1"> mdi-pause-octagon-outline </v-icon>
            <span class="text-subtitle-1">suspended</span>
          </v-chip>
        </v-col>
        <v-col cols="auto" v-else>
          <v-chip small dark color="green">
            <v-icon small class="mr-1"> mdi-check </v-icon>
            <span class="text-subtitle-1">active</span>
          </v-chip>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('closeDialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text class="pb-2">
      <v-row no-gutters v-show="provider.isSuspended">
        <v-alert dense outlined type="error" v-show="provider.adminMessage">
          Admin message: {{ provider.adminMessage }}
        </v-alert>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field
            class="text-caption"
            label="Business Name"
            v-model="provider.providerName"
            dense
            readonly
            hide-details
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            class="text-caption"
            label="Registration Number"
            v-model="provider.registrationNumber"
            dense
            readonly
            hide-details
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            class="text-caption"
            label="Provider Number"
            v-model="provider.providerNumber"
            dense
            readonly
            hide-details
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            class="text-caption"
            label="Approval Status"
            v-model="provider.approvalStatus"
            dense
            readonly
            hide-details
          />
        </v-col>
      </v-row>

      <!-- Offices information -->
      <v-card
        color="blue lighten-5"
        v-for="office in provider.offices"
        :key="office.officeId"
        class="px-5 my-1 pt-3 pb-1"
        flat
        outlined
      >
        <v-row>
          <v-col cols="12" sm="3" align-self="stretch">
            <v-chip color="primary">
              {{ office.officeName }} : {{ office.businessID }}
            </v-chip>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              class="text-caption"
              label="Office/Practitioner name"
              v-model="office.businessName"
              dense
              readonly
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              class="text-caption"
              label="Phone Number"
              v-model="office.phoneNumber"
              dense
              readonly
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              class="text-caption"
              label="Phone Extension"
              v-model="office.phoneExtension"
              dense
              readonly
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              class="text-caption"
              label="Mailing Address"
              v-model="office.address"
              dense
              readonly
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              class="text-caption"
              label="City"
              v-model="office.city"
              dense
              readonly
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              class="text-caption"
              label="Province"
              v-model="office.province"
              dense
              readonly
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              class="text-caption"
              label="Postal Code"
              v-model="office.postalCode"
              dense
              readonly
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              class="text-caption"
              label="Primary Contact Firstname"
              v-model="office.primaryContactFirstname"
              dense
              readonly
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              class="text-caption"
              label="Primary Contact Lastname"
              v-model="office.primaryContactLastname"
              dense
              readonly
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              class="text-caption"
              label="Email"
              v-model="office.directDepositEmail"
              dense
              readonly
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              class="text-caption"
              label="Verify Direct Deposit"
              v-model="office.verifyDirectDeposit"
              dense
              readonly
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              class="text-caption"
              label="Institution Number"
              v-model="office.institutionNumber"
              dense
              readonly
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              class="text-caption"
              label="Transit Number"
              v-model="office.transitNumber"
              dense
              readonly
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              class="text-caption"
              label="Account Type"
              v-model="office.accountType"
              dense
              readonly
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              class="text-caption"
              label="Account Number"
              v-model="office.accountNumber"
              dense
              readonly
              hide-details
            />
          </v-col>
        </v-row>
      </v-card>
      <v-row class="mt-1">
        <v-col cols="12" sm="3">
          <v-checkbox
            v-model="provider.verifyProviderRegistration"
            label="Verify Provider Registration"
            name="verifyProviderRegistration"
            hide-details
            :value="true"
            class="mt-4"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            class="text-caption"
            label="Timezone"
            v-model="provider.timezone"
            dense
            readonly
            hide-details
          />
        </v-col>
      </v-row>
      <!-- Provider Benefits -->
      <v-card class="pa-0" flat>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="3"
              v-for="coverage in provider.coverages"
              :key="coverage.coverageId"
              class="ma-0 pa-0"
            >
              <v-checkbox
                :label="coverage.name"
                :value="coverage"
                v-model="provider.coverages"
                color="primary"
                hide-details
                name="coverages"
                readonly
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="impersonate"
            v-if="user.profile.role === 'Administrator' || user.profile.role === 'SuperAdmin'"
          >
            Login As Provider
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- Security Information -->
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["provider", "loading"],
  data() {
    return {
      approvalStatus: "Pending",
      showPassword: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    async impersonate() {
      let returnUrl =
        process.env.VUE_APP_PROVIDERS_FRONTEND_LOGOUT_URI ||
        "http://localhost:8080";
      let identityServerUrl =
        process.env.VUE_APP_IDENTITY_SERVER_BACKEND_URL ||
        "https://localhost:5001/";
      let impersonateUrl = `${identityServerUrl}api/Impersonate/${this.provider.providerId}/?returnUrl=${returnUrl}`;
      console.log(impersonateUrl);
      //await api.get(impersonateUrl);
      window.location.href = impersonateUrl;
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
