<template>
  <v-expansion-panel>
    <v-expansion-panel-header>{{
      officeName || "Start entering office information below"
    }}</v-expansion-panel-header>
    <v-expansion-panel-content class="px-0">
      <v-card flat class="pa-0">
        <input type="hidden" name="officeStartId" :value="officeStartId" />
        <input type="hidden" name="officeId" :value="officeId || null" />
        <v-row class="mt-1">
          <v-col cols="12" sm="6" class="pa-0 px-sm-2">
            <v-text-field
              :hint="
                businessID
                  ? ''
                  : `Business ID will be generated or prefilled on approval`
              "
              persistent-hint
              dense
              class="mt-3"
              v-model.trim="businessID"
              label="Business ID"
              readonly
              disabled
              name="businessID"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" class="pa-0 px-sm-2">
            <v-text-field
              dense
              v-model.trim="businessName"
              :hint="
                businessName
                  ? ''
                  : `Business name will be generated or prefilled on approval`
              "
              persistent-hint
              label="Office/Practitioner name"
              class="mt-3"
              readonly
              disabled
              name="businessName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="pa-0 px-sm-2 mt-2">
            <v-text-field
              dense
              v-model="registrationNumber"
              :rules="regNumberRules"
              label="Registration Number"
              name="registrationNumber"
              hint="Please provide if applicable."
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="pa-0 px-sm-2 mt-2">
            <v-text-field
              dense
              v-model="officeName"
              :rules="textRules"
              label="Office Name"
              name="officeName"
              placeholder="1234567"
              hint="Use this field to identify this office. Example: Store 1, or Toronto office. You can use letters and numbers."
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" class="pa-0 px-sm-2">
            <v-text-field
              dense
              class="mt-3"
              v-model="phoneNumber"
              :rules="phoneRules"
              label="Phone Number"
              name="phoneNumber"
              type="tel"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" class="pa-0 px-sm-2">
            <v-text-field
              dense
              class="mt-3"
              v-model="phoneExtension"
              label="Phone Extension"
              name="phoneExtension"
              type="number"
              pattern=""
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="pa-0 px-sm-2">
            <v-text-field
              dense
              v-model="directDepositEmail"
              :rules="emailRules"
              label="Email"
              name="directDepositEmail"
              hint="This email will be used for sending payment notifications"
              class="mt-3"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="pa-0 px-sm-2">
            <v-text-field
              dense
              class="mt-3"
              v-model="address"
              :rules="textRules"
              label="Mailing Address"
              name="address"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="pa-0 px-sm-2">
            <v-text-field
              dense
              class="mt-3"
              v-model="city"
              :rules="textRules"
              label="City"
              name="city"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <!-- <v-col cols="12" sm="6" class="pa-0 px-sm-2">
            <v-text-field
              dense
              class="mt-3"
              v-model="province"
              :rules="textRules"
              label="Province"
              name="province"
              required
            ></v-text-field>
          </v-col> -->
          <v-col cols="12" sm="6" class="pa-0 px-sm-2">
            <v-autocomplete
              dense
              class="mt-3"
              v-model="provinceId"
              :items="provinces"
              label="Province"
              name="provinceId"
              item-text="name"
              item-value="id"
              :rules="provinceRules"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" class="pa-0 px-sm-2">
            <v-text-field
              dense
              class="mt-3"
              v-model="postalCode"
              :rules="textRules"
              label="Postal Code"
              name="postalCode"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="pa-0 px-sm-2">
            <v-text-field
              dense
              class="mt-3"
              v-model="primaryContactFirstname"
              label="Primary Contact First Name"
              name="primaryContactFirstname"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="pa-0 px-sm-2">
            <v-text-field
              dense
              class="mt-3"
              v-model="primaryContactLastname"
              label="Primary Contact Last Name"
              name="primaryContactLastname"
            ></v-text-field>
          </v-col>
        </v-row>
        <h2 class="text-h6 mb-2">Direct deposit</h2>
        <v-card flat class="mt-1">
          <v-row>
            <v-col cols="12" sm="4" class="pa-0 px-sm-2">
              <v-text-field
                dense
                class="mt-4"
                v-model="institutionNumber"
                :rules="institutionRules"
                :counter="3"
                label="Institution Number"
                type="number"
                name="institutionNumber"
                hint="This is a 3 digit number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="pa-0 px-sm-2">
              <v-text-field
                dense
                class="mt-4"
                v-model="transitNumber"
                :rules="transitRules"
                :counter="5"
                label="Transit Number"
                type="number"
                name="transitNumber"
                hint="This is a 5 digit number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="pa-0 px-sm-2">
              <v-text-field
                dense
                class="mt-4"
                v-model="accountNumber"
                :rules="accountNumberRules"
                :counter="12"
                label="Account Number"
                name="accountNumber"
                type="number"
                hint="The account number can be a 7 to 12 digit number"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" class="pa-0 px-sm-2">
              <v-checkbox
                dense
                v-model="verifyDirectDeposit"
                label="Verify Direct Deposit"
                :rules="verifyRule"
                name="verifyDirectDeposit"
                :value="true"
                required
                class="mb-2"
              ></v-checkbox>
            </v-col>
          </v-row>
          <small
            ><span class="font-weight-medium font-italic">IMPORTANT!</span> I
            hereby authorize The PBAS Group, and its Administrators, to deposit
            payment to my account as indicated above. I understand that this
            information will be kept confidential and secure, and that it will
            only be used for the purpose identified herein. This authorization
            may be terminated at any time upon written notice by me.</small
          >
        </v-card>

        <input type="hidden" name="officeEnd" />
      </v-card>
      <slot></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["officeStartId", "office", "email"],

  data() {
    return {
      businessName: "",
      businessID: "",
      officeId: "",
      registrationNumber: "",
      officeName: "",
      phoneNumber: "",
      phoneExtension: "",
      address: "",
      city: "",
      province: "",
      provinceId: null,
      postalCode: "",
      primaryContactFirstname: "",
      primaryContactLastname: "",
      institutionNumber: "",
      transitNumber: "",
      accountType: "Chequing",
      accountNumber: "",
      verifyDirectDeposit: false,
      directDepositEmail: "",
      areasOfSpecialization: [],
      textRules: [
        (v) => !!v || "Please Enter",
        // (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      verifyRule: [(v) => !!v || "Please verify"],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) =>
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
          "Phone must be valid",
      ],

      provinceRules: [
        (v) => !!v || "Please Select",
      ],
      institutionRules: [
        (v) => !!v || "Please Enter",
        (v) => (v && v.length === 3) || "Must be 3 numbers",
      ],
      transitRules: [
        (v) => !!v || "Please Enter",
        (v) => (v && v.length === 5) || "Must be 5 numbers",
      ],
      accountNumberRules: [
        (v) => !!v || "Please Enter",
        (v) => (v && v.length <= 12) || "Must be less than 12 numbers",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      imageRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Image size should be less than 2 MB!",
      ],
      // regNumberRules: [
      //   (v) => !!v || "Please Enter",
      // ],
      // regNumberRules: [
      //   (v) => /\d$/.test(v) || "Registration number must be valid",
      // ],
      accountTypes: ["Chequing", "Savings"],
    };
  },
  created() {
    this.officeId = this.office?.officeId || null;
    this.officeName = this.office?.officeName;
    this.registrationNumber = this.office?.registrationNumber;
    this.businessID = this.office?.businessID;
    this.businessName = this.office?.businessName;
    this.phoneNumber = this.office?.phoneNumber;
    this.phoneExtension = this.office?.phoneExtension;
    this.address = this.office?.address;
    this.city = this.office?.city;
    const provinceCode = this.provinces.find(
      (p) => p.id === this.office?.provinceId
    )?.code;
    this.province = this.office?.province || provinceCode;
    this.provinceId = this.office?.provinceId;
    this.postalCode = this.office?.postalCode;
    this.primaryContactFirstname = this.office?.primaryContactFirstname;
    this.primaryContactLastname = this.office?.primaryContactLastname;
    this.institutionNumber = this.office?.institutionNumber;
    this.transitNumber = this.office?.transitNumber;
    this.accountType = "Chequing";
    this.accountNumber = this.office?.accountNumber;
    this.verifyDirectDeposit = this.office?.verifyDirectDeposit;
    this.directDepositEmail = this.office?.directDepositEmail || this.email;
    this.verifyProviderRegistration = this.office?.verifyProviderRegistration;
    // this.officeIds = this.provider.offices.map((o, i) => i);
    // this.officeId = this.officeIds.length;
  },
  computed: {
    ...mapGetters("enums", ["provinces"]),
  },
  watch: {
    office: function () {
      this.officeId = this.office?.officeId || null;
      this.officeName = this.office?.officeName;
      this.registrationNumber = this.office?.registrationNumber;
      this.businessName = this.office?.businessName;
      this.businessID = this.office?.businessID;
      this.phoneNumber = this.office?.phoneNumber;
      this.phoneExtension = this.office?.phoneExtension;
      this.address = this.office?.address;
      this.city = this.office?.city;
      this.province = this.office?.province;
      this.postalCode = this.office?.postalCode;
      this.primaryContactFirstname = this.office?.primaryContactFirstname;
      this.primaryContactLastname = this.office?.primaryContactLastname;
      this.institutionNumber = this.office?.institutionNumber;
      this.transitNumber = this.office?.transitNumber;
      this.accountType = "Chequing";
      this.accountNumber = this.office?.accountNumber;
      this.verifyDirectDeposit = this.office?.verifyDirectDeposit;
      this.directDepositEmail = this.office?.directDepositEmail;
      this.verifyProviderRegistration = this.office?.verifyProviderRegistration;
    },
  },
};
</script>
