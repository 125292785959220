<template>
  <v-container>
    <v-card flat max-width="450" class="mx-auto mt-10">
      <v-row>
        <v-col>
          <v-img
            :src="require('@/assets/pbas_group_logo.svg')"
            width="400"
            contain
            class="float-left"
          />
        </v-col>
      </v-row>
      <v-col class="mt-2">
        <div class="text-h5 py-4">Something exciting is coming!</div>
        <p>
          We have been working on improving our claims system, and we are
          looking forward to having a more seamless way to receive and pay your
          claims.
        </p>
        <p>
          Please note that while we prepare for the changeover, this site will
          be undergoing scheduled maintenance to improve your user experience on
          August 31 - September 1, 2023. Claims will not be processed during
          this time, but can be held and submitted when the maintenance is
          completed.
        </p>
        <p>
          We thank for you for your patience and look forward to improving your
          claims experience.
        </p>
        <p>
          If you have questions, concerns, or inquires please contact the
          Administrator at: <a href="tel:1-800-563-1930">1-800-563-1930</a>.
        </p>

        <p>The PBAS Group</p>
      </v-col>
    </v-card>
  </v-container>
</template>
<script>
export default {};
</script>
<style scoped>
p {
  text-align: justify;
}
</style>
